<template>
  <div class="home">

    <top></top>
    <div class="center">
      <div class="nav-wrap">
        <div class="item" v-for="(item,i) in  expertlist" :key="i">
          <div class="list">
           <div class="img">
  <viewer>
  <img
    :src="item.image"
    style="width: 100px;height: 100px; cursor:pointer">
</viewer>
        </div>
            <router-link :to="{path:'specialistDetail',query:{id:item.uuid}}" tag="div" class="message">
              <div class="name">姓名：{{item.name}}</div>
              <p>
                {{item.describe}}
              </p>
            </router-link>
            <div class="questions">
              <el-button
                type="text"
                @click="dialogTableVisible = true"
                class="button"
                >问一问</el-button
              >
            </div>
            <div class="tan">
             <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false">
                <div class="confirm">
                  <el-form class="confirmform" label-width="80px">
                    <el-form-item label="标题">
                      <el-input v-model='formdata.title' placeholder="请输入您想问的问题标题..."></el-input>
                    </el-form-item>
                    <el-form-item label="描述">
                      <el-input class="desc" v-model='formdata.describe'  placeholder="请较为详细的记录您提问的问题，等待专家回复..." type="textarea" ></el-input>
                    </el-form-item>
                    <div class="describe">补充照片(最多三张)</div>
                    <el-form-item>
                   
                      <el-upload
                     :action="baseApi+ '/center/file/upload'"
                      list-type="picture-card"
                      :on-success="handlePictureCardPreview"
                      :before-upload="beforeAvatarUpload"
                        :headers="httpheader"
                      :on-remove="handleRemove">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                   <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button
                  type="danger"
                  class="fabu"
                  @click="reselve(item.uuid)"
                  >发布</el-button
                >
              </el-dialog>
</div>
          </div>
        </div>
          
    
        

      </div>
    </div>
    <div class="footer">
      <Foo></Foo>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'、
import { Message } from "element-ui";
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import Dialog from "./dialog.vue";
import expertApi from "../../api/expertApi"
import imageZip from "../../utils/imageZip"
import basecfg from "../../utils/applocationCfg"
export default {
  data() {
    return {
       httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
       baseApi:basecfg.getBaseApi(),
      token: "",
      dialogImageUrl: "",
      dialogTableVisible: false,
      centerDialogVisible: false,
      dialogVisible: false,
      formdata:{
        title:'',
        describe:'',
        fileList:[],
      
      },
      expertlist:"",
      //  uuid:this.$route.params.id,
     uuid:""     
    };
  },

  components: {
    // HelloWorld
    Top,
    Foo,
    Dialog
  },
  mounted() {
    this.gettoken();
    this.getexpertlist()
  },
  methods: {
        // 限制大小
         beforeAvatarUpload(file) {
      const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
      return imageZip.beforeUpload(file)
      
    },
    gettoken() {
      this.token = JSON.parse(localStorage.getItem("Login_data"));
      this.$store.state.home.token = JSON.parse(localStorage.getItem("Login_data"));
     
      if (this.token == "" || this.token == null) {
        this.$router.push("/login");
      }
    },
    handleRemove(file, fileList) {
     
    },
    handlePictureCardPreview(file) {
     
      this.dialogImageUrl = file.url;
    
      this.formdata.fileList.push(file.result)
    
    },
    
    reselve(expertid){
         if(this.formdata.describe&&this.formdata.title){
                 expertApi.questions({title:this.formdata.title,describe:this.formdata.describe,files:this.formdata.fileList,expertid:expertid},(res)=>{
        if(res.data.code==200){
  Message.success("发布成功");
         setTimeout(()=>{
            this.expertpronlemlist()
         },1000)
          this.formdata.describe=""
  
    this.formdata.fileList=null
    this.formdata.title=""
    this.dialogTableVisible=false
        }
        else{
          Message.error(res.data.message);
        }
       
   
     })
      }
      else{
          Message.error("请填写完整");
      }
 
    },
    //专家列表
    getexpertlist(uuid){
      expertApi.expertsList(uuid,(res)=>{
      
        this.expertlist = res.data.result
      
        this.uuid = res.data.result.uuid
        })
    }
  },
  mounted() {
var uuid=this.$route.params.id
   this.getexpertlist(uuid)
  },
};
</script>
<style scoped>
.center {
  width: 100%;
  height: 730px;
  border: 1px solid transparent;
  /* margin-top: 40px; */
  /* background-color: rgb(253, 253, 253); */
}
.home {
  background-color: rgb(245, 245, 245);
  /* min-width: 800px; */
/* width: 100%; */
min-width: 1100px;
  
}

.nav-wrap {
  /* border: 1px solid red; */
  height: 580px;
  width: 60%;
  margin:0 auto;
  margin-top: 2%;
  min-width: 800px;
}
.item {
  /* border: 1px solid red; */
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}
.list {
  display: flex;
  width: 100%;
  height: 96px;
  justify-content: space-between;
  padding: 10px;
  /* box-sizing: border-box; */
}

.img {
  /* border: 1px solid rgb(98, 0, 255); */
  width: 10%;
}
.img .viewer img {
  width: 100%;
  height: 100%;
}
.message {
  width: 76%;
  height: 80%;
  /* border: 1px solid rgb(60, 0, 255); */
  padding-top: 16px;
  overflow: hidden;
  border: 1px solid transparent; 
  
 text-overflow:ellipsis; 
white-space:wrap; 
}
.message .name {
  font-weight: 600;
  margin-bottom: 20px;
}
.message p {
  font-size: 14px;
  width: 100%;
  /* height: 30px; */
  left: 30px;
 
  /* border: 1px solid red; */
}
.questions {
  width: 10%;
  /* border: 1px solid rgb(60, 0, 255); */
}
.questions .button {
  background-color: rgb(233, 79, 85);
  border: 0;
  width: 70px;
  height: 30px;
  line-height: 5px;
  color: white;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}
.footer {
  /* border: 1px solid red; */
  margin-top: -40px;
}
.confirm {
  width: 600px;
  border-radius: 20px;
}
.tan ::v-deep .el-dialog {
  width: 800px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.confirmform {
  width: 100%;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
}
.desc > ::v-deep .el-textarea__inner {
  height: 300px;
  /* border: 1px solid red; */
  width: 100%;
}
.describe {
  margin-left: 40px;
}
.fabu {
  width: 300px;
  margin-left: 200px;
}
.el-icon-plus{
  /* border: 1px solid red; */
  position: relative;
  top: -20px;
}
</style>
