<template>
    <div class="app">
     <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false" :before-close="handleClose">
                <div class="confirm">
                  <el-form class="confirmform" label-width="80px">
                    <el-form-item label="标题">
                      <el-input v-model='formdata.title'></el-input>
                    </el-form-item>
                    <el-form-item label="描述">
                      <el-input class="desc" v-model='formdata.desc'></el-input>
                    </el-form-item>
                    <div class="describe">补充照片(最多三张)</div>
                    <el-form-item>
                      <el-upload
                        action=""
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :auto-upload="false"
                        :file-list="formdata.fileList"
                        :on-change='nn'
                        :before-upload="beforeUpload"
                        :headers="httpheader"
                      >
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button
                  type="danger"
                  class="fabu"
                   
                  @click="reselve"
                 
                  >发布</el-button
                >
              </el-dialog>
    </div>
</template>
<script>
import imageZip from "../../utils/imageZip"
export default {
    data(){
return{
   httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
     dialogImageUrl: "",
      dialogTableVisible: false,
      centerDialogVisible: false,
      dialogVisible: false,
      formdata:{
        title:'',
        desc:'',
        fileList:[]
      }
}
    },
    methods:{
          beforeUpload(file){
       const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
      return imageZip.beforeUpload(file)
    },
      // 2
       handleRemove(file, fileList) {
    
    },
    handleClose(){
      this.dialogTableVisible=true
     
    },
    // 1
    handlePictureCardPreview(file) {
    
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    nn(file){
    this.formdata.fileList=[...this.formdata.fileList,file]
  
    },
    reselve(){
    
      this.dialogImageUrl=''
      this.dialogTableVisible=false
     
    },
    }
}
</script>
<style scoped>

</style>