import baseApi from './baseApi.js';
const expertApi = {
 //专家列表
 expertsList(params,callback){
  baseApi.get("center/expertconsultations/getexpertlist",params,callback)
 },
 //获取专家信息
 getInformation(params,callback){
  baseApi.get('center/expertconsultations/getexpertinfo?expertId='+params.id,null,callback)
 },
 //获取咨询类表
 consultList(params,callback){
    baseApi.get('center/expertconsultations/getexpertpronlemlist?expertId='+params.id+"&pageNum="+params.pageNum+"&pageSize="+params.pageSize,null,callback)
 },
 //发起提问
 questions(params,callback){
    baseApi.post('center/expertconsultations/sendquestions',params,callback)
 },
 //文件上传
 upload(params,callback){
   baseApi.post('center/file/upload',params,callback)
}
}

//抛出api 
export default expertApi;
